import { usePrevious } from '@mantine/hooks';
import { Drawer } from 'antd';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { LocationDescriptor } from 'history';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory } from 'react-router-dom';
import { LogoVariant } from 'xo/models';
import { appRoutes } from 'xo/navigation/web-routes';
import { colors, width } from 'xo/styles/tailwind-theme';
import { SvgCaret } from 'xo/svg/svg-caret';
import { SvgCross } from 'xo/svg/svg-cross';
import { SvgExoFlareLogo } from 'xo/svg/svg-exoflare-logo';
import { isRunningInWebView } from 'xo/webview/webview-events';
import { useFlag, useIsXoAdmin } from '../../hooks/shared-hooks';
import {
  NavigationOption,
  useNavigationOptions,
} from '../../hooks/use-navigation-options';
import { getPermissions } from '../../utils/permissions';
import { UserContext, useCurrentUser } from '../account/user-provider';
import { AdminAssumeUser } from '../admin/admin-assume-user';
import { AdminDrawer } from '../admin/admin-drawer';
import { ArrivalDashboardUploadButton } from '../arrival-dashboard/arrival-dashboard-upload-button';
import { initials } from '../utils';
import { AdminRoute } from './admin-route';
import { AppHeader, AppHeaderProps } from './app-header';
import { KioskModeButton } from './kiosk-mode-button';
import { NavBarItem } from './navbar-item';

export interface NavBarProps extends Omit<AppHeaderProps, 'back'> {
  backTo?: LocationDescriptor;
  onBack?: () => void;
}

const transitionClassName = 'transition-all duration-200 ease-out';

const NavBar: React.FC<NavBarProps> = props => {
  const { inverted, collapse, backTo, onBack } = props;
  const { user } = useContext(UserContext);
  const permissions = getPermissions(user);

  const history = useHistory();
  const onBackCb = useCallback(() => {
    onBack && onBack();
    backTo && history.push(backTo);
  }, [history, backTo, onBack]);

  const [menuVisible, onSetMenuVisible, onUnsetMenuVisible] = useFlag(false);
  const [nestedMenuItem, onSetNestedMenuItem] = useState<NavigationOption>();
  const [nestedMenuVisible, onSetNestedMenuVisible, onUnsetNestedMenuVisible] =
    useFlag(false);

  const onCloseMenu = useCallback(() => {
    onUnsetMenuVisible();
    onUnsetNestedMenuVisible();
  }, [onUnsetMenuVisible, onUnsetNestedMenuVisible]);

  const prevCollapse = usePrevious(collapse);
  useEffect(() => {
    if (prevCollapse !== collapse && !collapse) {
      onCloseMenu();
    }
  }, [collapse, prevCollapse, onCloseMenu]);

  const onMenuItemClick = useCallback(
    async (item: NavigationOption) => {
      if (item.nested) {
        onSetNestedMenuItem(item);
        onSetNestedMenuVisible();
      } else if (item.onClick) {
        item.onClick();
      }

      if (!item.nested && !item.suppressCloseOnClick) {
        onCloseMenu();
      }
    },
    [onCloseMenu, onSetNestedMenuItem, onSetNestedMenuVisible],
  );

  const locationMatchesOption = useCallback(
    (o: NavigationOption) =>
      o.to &&
      ((o.activeExact && history.location.pathname === o.to) ||
        (!o.activeExact && history.location.pathname.includes(o.to))),
    [history],
  );

  const { xoUIHidden } = useContext(UserContext);

  const renderOptions = useCallback(
    ({
      options,
      initiallyVisible,
      visible,
      indexOffset = 0,
    }: {
      options: NavigationOption[];
      initiallyVisible: boolean;
      visible: boolean;
      indexOffset: number;
    }) => {
      return options
        .filter(o => !xoUIHidden || !o.admin)
        .map((o, i) => {
          const item = (
            <NavBarItem
              key={i}
              {...o}
              active={
                !!(
                  locationMatchesOption(o) ||
                  o.nested?.some(locationMatchesOption)
                )
              }
              onClick={onMenuItemClick}
              item={o}
            />
          );

          return (
            <motion.div
              key={i}
              initial={initiallyVisible ? 'visible' : 'hidden'}
              animate={visible ? 'visible' : 'hidden'}
              variants={{
                hidden: {
                  opacity: 0,
                  translateX: `-${width[64]}`,
                },
                visible: {
                  opacity: 1,
                  translateX: '0px',
                },
              }}
              transition={{
                duration: 0.3,
                delay: (i + indexOffset) / 20,
                bounce: 0,
              }}
            >
              {o.admin ? (
                <AdminRoute key={o.label} render={() => item} />
              ) : (
                item
              )}
            </motion.div>
          );
        });
    },
    [onMenuItemClick, locationMatchesOption, xoUIHidden],
  );

  const { top: topMenuOptions, bottom: bottomMenuOptions } =
    useNavigationOptions();

  const renderedTopOptions = renderOptions({
    options: topMenuOptions,
    initiallyVisible: true,
    visible: !nestedMenuVisible,
    indexOffset: 0,
  });

  const renderedBottomOptions = renderOptions({
    options: bottomMenuOptions,
    initiallyVisible: true,
    visible: !nestedMenuVisible,
    indexOffset: renderedTopOptions.length,
  });

  const logo = (
    <div className="flex items-center p-4">
      <Link
        to={{
          pathname: appRoutes.root,
        }}
      >
        <SvgExoFlareLogo variant={LogoVariant.WhiteOrange} />
      </Link>
    </div>
  );

  const sidebarContent = (
    <div className="flex h-full flex-col justify-between overflow-y-auto">
      <div>
        <div className="flex h-12 items-center justify-between border-b-2 border-solid border-white px-4 lg:hidden">
          <div className="text-lg font-semibold text-white">Menu</div>

          {
            // FIXME: Temporarily hiding kiosk mode button for webview until issues are resolved
            // https://exoflare.atlassian.net/browse/KX-567
            !isRunningInWebView() && <KioskModeButton className="lg:hidden" />
          }

          <button onClick={onUnsetMenuVisible}>
            <SvgCross fill={colors.white} />
          </button>
        </div>
        <div className="hidden lg:block">{logo}</div>
        <div className="relative">
          {renderedTopOptions}

          <div
            className="absolute left-0 top-0 w-full"
            style={{ zIndex: nestedMenuVisible ? 1 : -1 }}
          >
            {renderOptions({
              options: [
                {
                  id: 'back',
                  label: nestedMenuItem?.label,
                  icon: <SvgCaret direction="l" />,
                  onClick: onUnsetNestedMenuVisible,
                  suppressCloseOnClick: true,
                  admin: nestedMenuItem?.admin,
                } as NavigationOption,
              ].concat(nestedMenuItem?.nested ?? []),
              initiallyVisible: false,
              visible: nestedMenuVisible,
              indexOffset: 0,
            })}
          </div>
        </div>
      </div>
      <div className="lg:pb-16">
        <div>{renderedBottomOptions}</div>
        <div className="border-t-2 border-solid border-white lg:hidden lg:border-0">
          {logo}
        </div>
      </div>
    </div>
  );

  const isAdmin = useIsXoAdmin();
  const currentUser = useCurrentUser();
  const isAssumingAdmin = isAdmin && currentUser.kind !== 'XO_ADMIN';

  // antd applies fixed width of 378px to sidebar
  const isXs = useMediaQuery({ maxWidth: '400px' });

  return (
    <>
      <AppHeader
        onClick={backTo || onBack ? onBackCb : onSetMenuVisible}
        back={!!(backTo || onBack)}
        {...props}
        sidebar={sidebarContent}
        right={
          <>
            {permissions.visitor &&
            // FIXME: Temporarily hiding kiosk mode button for webview until issues are resolved
            // https://exoflare.atlassian.net/browse/KX-567
            !isRunningInWebView() ? (
              <div className="hidden md:block">
                <KioskModeButton />
              </div>
            ) : null}

            <ArrivalDashboardUploadButton responsive />

            <AdminRoute>
              <AdminDrawer />

              <div className="hidden 2xl:block">
                <div className="ml-2">
                  <AdminAssumeUser />
                </div>
              </div>
            </AdminRoute>
          </>
        }
        profile={
          <Link
            className={classNames(
              'flex h-9 w-9 items-center justify-center rounded-full border-2 border-solid text-sm font-semibold',
              transitionClassName,
              {
                'border-blue-400 bg-white text-blue-400 hover:text-blue-400':
                  !inverted && !isAssumingAdmin,
                'border-white bg-blue-400 text-white hover:text-white':
                  inverted && !isAssumingAdmin,
                // Red when user is an admin who's assuming someone
                'border-white bg-red-800 text-white hover:text-white':
                  isAssumingAdmin,
              },
            )}
            to={appRoutes.settings.userDetails(user.id)}
          >
            {initials(user.name)}
          </Link>
        }
      />

      <Drawer
        className={classNames('print:hidden', { 'lg:hidden': !collapse })}
        placement="left"
        closable={false}
        onClose={onUnsetMenuVisible}
        visible={menuVisible}
        bodyStyle={{ padding: 0, backgroundColor: colors.blue[600] }}
        width={isXs ? '95%' : undefined}
      >
        {sidebarContent}
      </Drawer>
    </>
  );
};

export default NavBar;
