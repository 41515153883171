import { useWindowEvent } from '@mantine/hooks';
import posthog, { Properties } from 'posthog-js';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import getClientId from 'xo/client-id';
import { UserFeatureFlag } from 'xo/constants';
import { ApiOrganisationFragment } from 'xo/graphql/api/organisation-fragment.generated';
import { ApiSiteFragment } from 'xo/graphql/api/site-fragment.generated';
import { firstNameAndInitial } from 'xo/utils/string-utils';
import { omitFields } from 'xo/utils/transform-utils';
import { isRunningInWebView } from 'xo/webview/webview-events';
import { UserContext, useCurrentUser } from '../app/account/user-provider';
import { env } from '../app/env';
import { useGetVisitorLocalStorage } from '../app/people-check-in/check-in-hooks';
import { LocalStorage } from '../app/shared/local-storage';
import { useIsXoAdmin } from './shared-hooks';

enum PostHogFeatureFlag {
  RecordingEnabled = 'recording_enabled',
}

export enum AnalyticsEvent {
  SiteEdit = 'Site Edit',
  VisitCheckIn = 'Visit Check-in',
  DeliveryCheckIn = 'Delivery Check-in',
  ConsignmentNotePdfDownloaded = 'Consignment Note PDF',
  ConsignmentNoteEdit = 'Consignment Note Edit',
  DeliveryClickAwarenessLink = 'CSU 2023: Click farmbio link',
}

export enum AnalyticsObject {
  AddressBookLocation = 'Address Book Location',
  AddressBookContact = 'Address Book Contact',
  Vehicle = 'Vehicle',
  Outbreak = 'Outbreak',
  Site = 'Site',
}

const token = () => env.REACT_APP_POSTHOG_TOKEN;
const xoEmail = '@exoflare.io';

const checkPostHogFeatureFlags = (reload: boolean) => {
  if (token()) {
    reload && posthog.reloadFeatureFlags();
    posthog.onFeatureFlags(flags => {
      if (
        posthog.isFeatureEnabled(PostHogFeatureFlag.RecordingEnabled, {
          send_event: false,
        })
      ) {
        posthog.startSessionRecording();
      } else {
        posthog.stopSessionRecording();
      }
    });
  }
};
checkPostHogFeatureFlags(false);

export const useAnalytics = () => {
  const { pathname } = useLocation();
  const user = useCurrentUser();
  const { assumedUserId, potentiallyAnAdminUser } = useContext(UserContext);
  const visitor = useGetVisitorLocalStorage({
    organisationId: undefined,
    organisationName: undefined,
  });
  const isXoAdmin = useIsXoAdmin();

  // Init posthog
  useEffect(() => {
    if (token()) {
      posthog.init(token(), {
        api_host: 'https://eu.posthog.com',
        // https://posthog.com/docs/integrate/client/js#persistence
        // We'll want to track across exoflare.io and app.exoflare.io, so store the
        // distinct ID in the cookie
        persistence: 'cookie',
        // We'll track pageviews ourselves
        capture_pageview: false,
        // Automatically captures links, button etc clicks
        autocapture: {
          dom_event_allowlist: ['click'],
          element_allowlist: ['a', 'button'],
        },
        secure_cookie: true,
        // Controlled instead by a feature flag in posthog and the onFeatureFlags handler above
        disable_session_recording: true,
        // Needed to try filtering out internal users
        ip: true,
      });

      posthog.register({
        commitBackend: LocalStorage.commitBackend,
        commitFrontend: LocalStorage.commitFrontend,
        clientId: getClientId(),
        app: isRunningInWebView() ? 'webview' : 'web',
      });
    }
  }, []);

  // Identify user when these values change
  useEffect(() => {
    if (token()) {
      posthog.identify(
        potentiallyAnAdminUser?.id ?? user?.id ?? visitor?.id,
        undefined,
        {
          userId: user?.id,
          name: user ? firstNameAndInitial(user.name) : undefined,
          assumerId: assumedUserId ? potentiallyAnAdminUser?.id : undefined,
          visitorId: visitor?.id,
          organisationId: user?.organisation?.id,
          organisationName: user?.organisation?.name,
          mobileEnabled: user?.features.includes(UserFeatureFlag.NativeApp),
          xo: !!(
            isXoAdmin ||
            user?.email?.endsWith(xoEmail) ||
            visitor?.email?.endsWith(xoEmail)
          ),
        },
      );
    }
  }, [user, visitor, isXoAdmin, potentiallyAnAdminUser, assumedUserId]);

  // Track page views
  useEffect(() => {
    if (token()) {
      posthog.capture('$pageview');
    }
  }, [pathname]);

  useWindowEvent('focus', () => checkPostHogFeatureFlags(true));
};

export const resetAnalytics = () => {
  if (token()) {
    posthog.reset(false);
  }
};

type CaptureOrganisation = Pick<ApiOrganisationFragment, 'id' | 'name'>;

export interface CaptureEventData extends Record<string, any> {
  site?: Pick<ApiSiteFragment, 'id' | 'name'> & {
    organisation?: CaptureOrganisation;
  };
  organisation?: CaptureOrganisation;
}

export const captureEvent = (eventName: string, data?: CaptureEventData) => {
  if (token()) {
    let captureData = data ?? {};
    const { site, organisation } = captureData;

    const addOrganisation = (organisation: CaptureOrganisation) => {
      captureData = {
        ...captureData,
        organisationId: organisation.id,
        organisationName: organisation.name,
      };
    };

    if (site) {
      captureData = {
        ...captureData,
        siteId: site.id,
        siteName: site.name,
      };

      // add the site org if provided, will be overwritten if manually specified
      if (site.organisation) {
        addOrganisation(site.organisation);
      }
    }

    if (organisation) {
      addOrganisation(organisation);
    }

    posthog.capture(
      eventName,
      omitFields(captureData, ['site', 'organisation']),
    );
  }
};

export const register = (properties: Properties) => {
  if (token()) {
    posthog.register(properties);
  }
};

export const unregister = (property: string) => {
  if (token()) {
    posthog.unregister(property);
  }
};
